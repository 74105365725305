import React, { useState, useEffect, useRef } from 'react';
import { TextField, Box, Typography, CircularProgress, Button, Alert } from '@mui/material';
import api from '../../authMiddleware';

function OptionalForm({ data, onDataChange }) {
  const [orderingQuestions, setOrderingQuestions] = useState(data.purchaseRules?.questions || []);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(orderingQuestions.length === 0);
  const [error, setError] = useState('');
  const hasFetchedQuestions = useRef(false); // Ensure API is called only once

  const questionsPerPage = 5;

  useEffect(() => {
    if (!hasFetchedQuestions.current && orderingQuestions.length === 0 && data?.eventDetails?.description && data?.coordinate?.supply) {
      fetchOrderingQuestions();
      hasFetchedQuestions.current = true; // Mark API call as done
    }
  }, [data]);

  const fetchOrderingQuestions = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await api.post('/negotiate/generate-ordering-questions/', {
        event_description: data.eventDetails.description,
        supply: data.coordinate.supply,
      });

      if (response.status === 200 && response.data?.questions) {
        setOrderingQuestions(response.data.questions);
        onDataChange({
          ...data,
          purchaseRules: {
            ...data.purchaseRules,
            questions: response.data.questions,
          },
        });
      } else {
        throw new Error(`Unexpected API response: ${JSON.stringify(response.data)}`);
      }
    } catch (err) {
      setError(`Failed to fetch ordering questions. ${err.message}`);
    }

    setLoading(false);
  };

  const handleAnswerChange = (e, index) => {
    const globalIndex = currentPage * questionsPerPage + index;
    onDataChange({
      ...data,
      purchaseRules: {
        ...data.purchaseRules,
        [`question_${globalIndex}`]: e.target.value,
        manualRules: data.purchaseRules?.manualRules || [], // Preserve manual rules
      },
    });
  };

  const handleManualRuleChange = (e, type) => {
    onDataChange({
      ...data,
      [type]: {
        ...data[type],
        manualRules: e.target.value.split("\n"),
      },
    });
  };

  const totalPages = Math.ceil(orderingQuestions.length / questionsPerPage);
  const paginatedQuestions = orderingQuestions.slice(
    currentPage * questionsPerPage,
    (currentPage + 1) * questionsPerPage
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h6">Optional Rules</Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">
          {error}
          <Button onClick={fetchOrderingQuestions} sx={{ marginLeft: 2 }} variant="outlined">
            Retry
          </Button>
        </Alert>
      ) : (
        paginatedQuestions.map((question, index) => (
          <TextField
            key={index}
            label={question}
            value={data.purchaseRules?.[`question_${currentPage * questionsPerPage + index}`] || ''}
            onChange={(e) => handleAnswerChange(e, index)}
            fullWidth
            multiline
          />
        ))
      )}

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
          <Button
            variant="outlined"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
            disabled={currentPage === 0}
          >
            Previous
          </Button>
          <Typography>
            Page {currentPage + 1} of {totalPages}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))}
            disabled={currentPage === totalPages - 1}
          >
            Next
          </Button>
        </Box>
      )}

      {/* Manual Rules Section */}
      <Typography variant="h6" sx={{ mt: 3 }}>Manual Rules</Typography>

      <Typography variant="subtitle1">Purchase-Related Manual Rules</Typography>
      <TextField
        label="Enter each purchase rule on a new line"
        multiline
        rows={4}
        value={(data.purchaseRules?.manualRules || []).join("\n")}
        onChange={(e) => handleManualRuleChange(e, 'purchaseRules')}
        fullWidth
      />

      <Typography variant="subtitle1">Email Communication Rules</Typography>
      <TextField
        label="Enter each email rule on a new line"
        multiline
        rows={4}
        value={(data.emailGenerationRules?.manualRules || []).join("\n")}
        onChange={(e) => handleManualRuleChange(e, 'emailGenerationRules')}
        fullWidth
      />
    </Box>
  );
}

export default OptionalForm;
