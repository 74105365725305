import React, { useState } from 'react';
import { Box, Button, Stepper, Step, StepLabel, Typography, Paper, Container, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../authMiddleware';
import MandatoryForm from './wizard/MandatoryForm';
import OptionalForm from './wizard/OptionalForm';
import ConfirmationForm from './wizard/ConfirmationForm';

function WizardPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Redirect function
  const [formData, setFormData] = useState({
    eventDetails: { description: '' },
    coordinate: { supply: '', supplierEmail: '' },
    purchaseRules: { questions: [], manualRules: [] },
    emailGenerationRules: { questions: [], manualRules: [] },
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const steps = [
    'Project & Counterparty Details',
    'Additional Information',
    'Confirmation',
  ];

  const handleNext = () => {
    if (activeStep === 0) {
      if (!formData.eventDetails.description || !formData.coordinate.supply || !formData.coordinate.supplierEmail) {
        setErrorMessage('All fields in Project & Counterparty Details are required.');
        setOpenSnackbar(true);
        return;
      }
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
  const handleStartAgain = () => setActiveStep(0);

  const handleDataChange = (updatedData) => {
    setFormData((prevData) => ({
      ...prevData, // Keep all existing data
      ...updatedData, // Merge new changes
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      // Extract and filter only non-empty rules
      const confirmedPurchaseRules = Object.keys(formData.purchaseRules)
        .filter((key) => key.startsWith('question_') && formData.purchaseRules[key]?.trim()) // Ignore empty answers
        .map((key) => formData.purchaseRules[key])
        .concat(formData.purchaseRules.manualRules?.filter(rule => rule.trim() !== "") || []); // Only add non-empty manual rules

      const confirmedEmailRules = Object.keys(formData.emailGenerationRules)
        .filter((key) => key.startsWith('question_') && formData.emailGenerationRules[key]?.trim()) // Ignore empty answers
        .map((key) => formData.emailGenerationRules[key])
        .concat(formData.emailGenerationRules.manualRules?.filter(rule => rule.trim() !== "") || []); // Only add non-empty manual rules

      // Step 1: Create Event
      const eventResponse = await api.post('/negotiate/events/', {
        name: formData.eventDetails.description.substring(0, 50), // Shorten as event name
        description: formData.eventDetails.description,
      });
      const eventId = eventResponse.data.id;

      // Step 2: Create Purchase Rules (only if there are valid rules)
      const purchaseRuleIds = [];
      if (confirmedPurchaseRules.length > 0) {
        for (const rule of confirmedPurchaseRules) {
          const purchaseRuleResponse = await api.post('/negotiate/purchase-rules/', { rule });
          purchaseRuleIds.push(purchaseRuleResponse.data.id);
        }
      }

      // Step 3: Create Email Generation Rules (only if there are valid rules)
      const emailRuleIds = [];
      if (confirmedEmailRules.length > 0) {
        for (const rule of confirmedEmailRules) {
          const emailRuleResponse = await api.post('/negotiate/email-generation-rules/', { rule });
          emailRuleIds.push(emailRuleResponse.data.id);
        }
      }

      // Step 4: Create Negotiation
      await api.post('/negotiate/negotiations/', {
        name: formData.coordinate.supply,
        supply: formData.coordinate.supply,
        supplier: formData.coordinate.supplierEmail,
        event: eventId,
        purchaserules: purchaseRuleIds.length > 0 ? purchaseRuleIds : [], // Avoid sending empty array
        emailgenerationrules: emailRuleIds.length > 0 ? emailRuleIds : [], // Avoid sending empty array
      });

      setSuccessMessage('Submission successful! Your negotiation is now created.');
      setOpenSnackbar(true);
      setLoading(false);

      navigate('/coordinates'); // ✅ Redirect after submission
    } catch (err) {
      console.error("❌ API Error:", err);
      setErrorMessage('An error occurred while submitting the data. Please try again.');
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" color="primary" gutterBottom>
        AI Coordinator
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper sx={{ padding: 3, marginTop: 3 }}>
        {activeStep === 0 && <MandatoryForm data={formData} onDataChange={handleDataChange} />}
        {activeStep === 1 && <OptionalForm data={formData} onDataChange={handleDataChange} />}
        {activeStep === 2 && <ConfirmationForm data={formData} onConfirm={handleSubmit} />}
      </Paper>

      {loading ? (
        <CircularProgress sx={{ display: 'block', margin: 'auto', mt: 2 }} />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
          {activeStep > 0 && (
            <Button variant="outlined" color="primary" onClick={handleBack}>
              Back
            </Button>
          )}
          {activeStep === steps.length - 1 ? (
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          )}
        </Box>
      )}

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={successMessage ? "success" : "error"}>
          {successMessage || errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default WizardPage;
