import React from 'react';
import { Drawer, List, ListItem, ListItemText, Toolbar, Typography, AppBar, Box, IconButton, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import EventIcon from '@mui/icons-material/Event';
import { Link, useNavigate } from 'react-router-dom';

import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import PercentIcon from '@mui/icons-material/Percent';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import api from '../api'; // Import the centralized API instance

const drawerWidth = 240;

const NavDrawer = ({ children }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    const refreshToken = localStorage.getItem('refresh');
    if (!refreshToken) {
      console.error('No refresh token found.');
      localStorage.clear();
      navigate('/login');
      return;
    }

    try {
      // Use the API instance to call the logout endpoint
      await api.post('/account/logout/', { refresh: refreshToken });
      console.log('Logout successful');
    } catch (error) {
      console.error('Logout API failed:', error.response?.data || error.message);
    } finally {
      // Clear local storage and redirect to login
      localStorage.clear();
      navigate('/login');
    }
  };

  const drawer = (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh',borderRight:'0px' }}>
      <Toolbar
        sx={{
          backgroundColor: 'primary.main',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          borderRight: '0px'
        }}
      >
        <Typography variant="h6">Feba Control Panel</Typography>
      </Toolbar>
      <List sx={{ flex: 1 }}>
        {/* <ListItem button component={Link} to="/dashboard" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <DashboardCustomizeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem> */}
        <ListItem button component={Link} to="/wizard" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <PlayCircleFilledWhiteIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Let’s start" />
        </ListItem>
        {/* <ListItem button component={Link} to="/events" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <EventIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="My projects" />
        </ListItem> */}
        <ListItem button component={Link} to="/coordinates" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <PublishedWithChangesIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Project’s process" />
        </ListItem>
        <ListItem button component={Link} to="/profile" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <ManageAccountsIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="My Profile" />
        </ListItem>
      </List>
      <ListItem button onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
      <ListItem sx={{ mt: 'auto', borderTop: '1px solid #ddd' }}>
        <ListItemIcon>
          <PercentIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Version 0.0.2" />
      </ListItem>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: 'primary.main',
          boxShadow: 'none', 
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth ,border: 'none',boxShadow: 'none', borderRight:'0px'},
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,borderRight:'0px'},
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          backgroundColor: '#f5f5f5',
          minHeight: '100vh',
          marginTop: '64px', // Ensure it doesn't overlap with the AppBar
          boxSizing: 'border-box',
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default NavDrawer;
