import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Button,
} from '@mui/material';

function ConfirmationForm({ data, onConfirm }) {
  const [loading, setLoading] = useState(true);
  const [combinedPurchaseRules, setCombinedPurchaseRules] = useState([]);
  const [combinedEmailRules, setCombinedEmailRules] = useState([]);

  useEffect(() => {
    if (data.purchaseRules?.questions?.length) {
      combinePurchaseRules();
    }
    if (data.emailGenerationRules?.questions?.length || (data.emailGenerationRules?.manualRules?.length > 0)) {
      combineEmailRules();
    }
    setLoading(false);
  }, [data]);

  const combinePurchaseRules = () => {
    const questions = data.purchaseRules.questions || [];
    const answers = Object.keys(data.purchaseRules)
      .filter((key) => key.startsWith('question_') && data.purchaseRules[key]?.trim()) // Ignore empty answers
      .map((key) => ({
        question: questions[parseInt(key.split('_')[1])],
        answer: data.purchaseRules[key],
      }));

    let combinedRules = answers.map(({ question, answer }) => `${question}: ${answer}`);

    // Include manual purchase rules, but filter out empty ones
    if (data.purchaseRules?.manualRules?.length) {
      const filteredManualRules = data.purchaseRules.manualRules.filter((rule) => rule.trim() !== "");
      if (filteredManualRules.length > 0) {
        combinedRules = [
          ...combinedRules,
          ...filteredManualRules.map((rule) => `Manual Purchase Rule: ${rule}`),
        ];
      }
    }

    setCombinedPurchaseRules(combinedRules);
  };

  const combineEmailRules = () => {
    const questions = data.emailGenerationRules.questions || [];
    const answers = Object.keys(data.emailGenerationRules)
      .filter((key) => key.startsWith('question_') && data.emailGenerationRules[key]?.trim()) // Ignore empty answers
      .map((key) => ({
        question: questions[parseInt(key.split('_')[1])],
        answer: data.emailGenerationRules[key],
      }));

    let combinedRules = answers.map(({ question, answer }) => `${question}: ${answer}`);

    // ✅ Include manual email generation rules, but remove empty ones
    if (data.emailGenerationRules?.manualRules?.length) {
      const filteredManualEmailRules = data.emailGenerationRules.manualRules.filter((rule) => rule.trim() !== "");
      if (filteredManualEmailRules.length > 0) {
        combinedRules = [
          ...combinedRules,
          ...filteredManualEmailRules.map((rule) => `Manual Email Rule: ${rule}`),
        ];
      }
    }

    setCombinedEmailRules(combinedRules);
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6">Project Details</Typography>
            <Typography><strong>Project Description:</strong> {data.eventDetails.description}</Typography>

            {/* Purchase Rules */}
            {combinedPurchaseRules.length > 0 && (
              <>
                <Typography variant="h6" sx={{ mt: 3 }}>Combined Purchase Rules</Typography>
                {combinedPurchaseRules.map((rule, index) => (
                  <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
                    <Typography>{rule}</Typography>
                  </Paper>
                ))}
              </>
            )}

            {/* Email Generation Rules */}
            {combinedEmailRules.length > 0 && (
              <>
                <Typography variant="h6" sx={{ mt: 3 }}>Combined Email Generation Rules</Typography>
                {combinedEmailRules.map((rule, index) => (
                  <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
                    <Typography>{rule}</Typography>
                  </Paper>
                ))}
              </>
            )}
          </Box>

          <Button variant="contained" color="primary" sx={{ marginTop: 3 }} onClick={onConfirm}>
            Submit
          </Button>
        </>
      )}
    </>
  );
}

export default ConfirmationForm;
