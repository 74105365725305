import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavDrawer from './components/NavDrawer';
import ProfilePage from './pages/ProfilePage';
import CoordinatesPage from './pages/CoordinatesPage';
import EventsPage from './pages/EventsPage';
import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import WizardPage from './pages/WizardPage';
import ProtectedRoute from './components/ProtectedRoute';
import ResetPasswordPage from './pages/ResetPasswordPage';

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ResetPasswordPage />} />

        {/* Protected Routes */}
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <NavDrawer>
                <Routes>
                  <Route path="/" element={<WizardPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/coordinates" element={<CoordinatesPage />} />
                  <Route path="/events" element={<EventsPage />} />
                  <Route path="/dashboard" element={<DashboardPage />} />
                  <Route path="/wizard" element={<WizardPage />} />
                </Routes>
              </NavDrawer>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
