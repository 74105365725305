import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Modal,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import api from '../authMiddleware';
import { format } from 'date-fns';
function CoordinatesPage() {
  const [coordinates, setCoordinates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [chatData, setChatData] = useState({});
  const [selectedCoordId, setSelectedCoordId] = useState(null);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const response = await api.get('/negotiate/negotiations/');
        setCoordinates(response.data);
      } catch (err) {
        setError('Failed to load coordinates');
      } finally {
        setLoading(false);
      }
    };
    fetchCoordinates();
  }, []);

  const fetchConversations = async (coordinateId) => {
    try {
      // Making a GET request instead of POST, using the given URL with query parameters
      const response = await api.get(`/communication/emails/history/`, {
        params: { negotiate_id: coordinateId }, // Sending the negotiate_id as a query parameter
      });
  
      // Assuming the response contains a property 'emails' with conversation data
      setChatData(response.data.emails || {});
      setOpen(true);
    } catch (err) {
      setError('Failed to fetch conversation details');
    }
  };
  

  const handleDetailClick = (coordinateId) => {
    setSelectedCoordId(coordinateId);
    fetchConversations(coordinateId);
  };

  const handleClose = () => {
    setOpen(false);
    setChatData({});
  };

  // Chat bubble style
  const getMessageStyle = (sender) => {
    if (sender.toLowerCase().includes('supplier')) {
      return {
        alignSelf: 'flex-start',
        backgroundColor: '#e0f7fa',
        color: '#000',
        borderRadius: '16px 16px 16px 4px',
      };
    } else if (sender.toLowerCase().includes('customer') || sender.toLowerCase().includes('client')) {
      return {
        alignSelf: 'flex-end',
        backgroundColor: '#c8e6c9',
        color: '#000',
        borderRadius: '16px 16px 4px 16px',
      };
    }
    return {
      alignSelf: 'flex-start',
      backgroundColor: '#e0e0e0',
      color: '#000',
      borderRadius: '16px',
    };
  };

  // Transform sender title
  const formatSenderTitle = (sender) => {
    if (sender.toLowerCase().includes('received_supplier')) {
      return "Counterparty"
    }
    else if (sender.toLowerCase().includes('received_client')) {
      return "Client"
    }
    else{
      return "Feba"
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" color="primary" gutterBottom>
      Projects progress
      </Typography>

      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}

      {!loading && !error && (
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            overflow: 'hidden',
            maxWidth: isMobile ? '100%' : '100%',
            margin: '0px',
          }}
        >
          <Table size={isMobile ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Counterparty request</TableCell>
                <TableCell>Counterparty Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Datetime</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {coordinates.map((coord, index) => (
              <TableRow key={index} hover>
                <TableCell>{coord.name}</TableCell>
                <TableCell>{coord.supply}</TableCell>
                <TableCell>{coord.supplier}</TableCell>
                <TableCell>{coord.status}</TableCell>
                
                {/* Format the datetime before rendering */}
                <TableCell>{format(new Date(coord.datetime), 'PPPpp')}</TableCell>

                <TableCell align="center">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleDetailClick(coord.id)}
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: isMobile ? 2 : 0,
        }}
      >
        <Paper
          sx={{
            padding: 3,
            width: isMobile ? '90%' : '50%',
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
          Project progress 
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              maxHeight: '60vh',
              overflowY: 'auto',
              padding: 2,
            }}
          >
            {Object.keys(chatData).length > 0 ? (
              Object.entries(chatData).map(([key, value], index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    maxWidth: '75%',
                    wordWrap: 'break-word',
                    ...getMessageStyle(key),
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    {formatSenderTitle(key)} {/* Format the sender title */}
                  </Typography>
                  <Typography variant="body1">{value}</Typography>
                </Box>
              ))
            ) : (
              <Typography>No conversations available.</Typography>
            )}
          </Box>
          <Button variant="contained" onClick={handleClose} fullWidth sx={{ mt: 2 }}>
            Close
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
}

export default CoordinatesPage;
