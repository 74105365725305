import React from 'react';
import { TextField, Box } from '@mui/material';

function MandatoryForm({ data, onDataChange }) {
  const handleChange = (e) => {
    const { name, value } = e.target;

    onDataChange({
      ...data, // Preserve the existing form data
      eventDetails: {
        ...data.eventDetails,
        ...(name === "description" ? { description: value } : {}),
      },
      coordinate: {
        ...data.coordinate,
        ...(name !== "description" ? { [name]: value } : {}),
      },
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        label="These details help us make the best decision for your project needs *"
        name="description"
        value={data.eventDetails?.description || ''}
        onChange={handleChange}
        fullWidth
        multiline
        rows={8}
        required
      />

      <TextField
        label="Briefly describe your requirement *"
        name="supply"
        value={data.coordinate?.supply || ''}
        onChange={handleChange}
        fullWidth
        multiline
        rows={2}
        required
      />
      <TextField
        label="Write the email address of the desired provider *"
        name="supplierEmail"
        type="email"
        value={data.coordinate?.supplierEmail || ''}
        onChange={handleChange}
        fullWidth
        required
      />
    </Box>
  );
}

export default MandatoryForm;
